import styles from './LegalNotice.module.scss';

import { Helmet } from 'react-helmet-async';

function LegalNotice() {
  return (
    <div className={styles.host}>
      <Helmet>
        <title>Mentions légales | formation.tech</title>
        <link rel="canonical" href="https://formation.tech/mentions-legales" />
      </Helmet>
      <b>formation.tech</b> est un site web édité par
      <address>
        bioub up
        <br />
        19 rue de Rome
        <br />
        75008 Paris
      </address>
      <p>
        SARL au capital de 10.000 euros
        <br />
        Paris B 539 162 065 NAF 6201Z
        <br />
        N° TVA intracommunautaire : <b>FR52539162065</b>
      </p>
      <p>hello@formation.tech</p>
      <p>
        Notre centre de formation est un organisme privé dispensateur de formation professionnelle continue,exerçant
        dans le cadre des dispositions du Code du Travail - Sixième partie - Livre III - Titre V - Art. L6353-1 et svts.
        Enregistrement auprès de la Préfecture de la Région Ile-de-France (DIRECCTE IDF - Service régional de contrôle
        de la formation professionnelle – 19 rue Madeleine Vionnet 93300 Aubervilliers) le 26/08/2016 sous le N°{' '}
        <b>11 75 55047 75</b>
      </p>
    </div>
  );
}

export default LegalNotice;
