export const transportIcons = {
  Metro: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="132.515" fill="#FFF" stroke="#25303B" stroke-width="18.43" />
      <path
        fill="#25303B"
        d="M212.25 205.15V81.5c0-7.09-3.9-14.53-15.6-14.53-8.85 0-12.4 3.9-16.29 11.69l-38.27 79.73h-.35l-38.63-79.73C99.21 70.87 95.67 67 86.81 67c-11.69 0-15.59 7.41-15.59 14.5v123.65c0 6.74 5.32 10.64 11.7 10.64 5.66 0 12-3.9 12-10.64V113h.36l35.12 71.6c2.47 5 5.67 7.8 11.34 7.8s8.85-2.84 11.33-7.8l35.08-71.6h.36v92.12c0 6.74 6.37 10.64 12.05 10.64 6.37 0 11.69-3.9 11.69-10.64"
      />
    </svg>
  ),
  RER: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect
        width="265.015"
        height="265.015"
        x="9.223"
        y="9.223"
        fill="#FFF"
        stroke="#25303B"
        stroke-width="18.445"
        rx="54.565"
      />
      <path
        fill="#25303B"
        d="M243.43 119.77c0-19.13-14.18-28-28-28h-26.57c-5 0-7.79 3.89-7.79 8.15v83.28c0 5 5 7.43 9.56 7.43 5.32 0 9.57-2.47 9.57-7.43V151h8.15l15.94 35.78a7 7 0 0 0 6.74 3.89c5.66 0 13.82-5.31 10.63-11.68l-15.24-31.89c9.21-4.62 17-12.76 17-27.29m-71.92 61.61c0-4.26-2.84-8.85-7.8-8.85h-26.93v-24.45h22.32a7.93 7.93 0 0 0 7.8-8.15c0-4.25-2.84-8.16-7.8-8.16h-22.32v-22.32h24.81c5 0 7.79-5 7.79-8.85 0-4.26-2.83-8.86-7.79-8.86h-36.15c-5 0-7.79 3.89-7.79 8.15v82.91c0 5 5 7.44 9.57 7.44h36.49c5 0 7.8-5 7.8-8.86m-65.21-61.65c0-19.13-14.16-28-28-28H51.73c-4.95 0-7.79 3.89-7.79 8.15v83.28c0 5 5 7.43 9.56 7.43 5.31 0 9.56-2.47 9.56-7.43V151h8.16l16 35.78a7 7 0 0 0 6.73 3.89c5.67 0 13.81-5.31 10.63-11.68l-15.29-31.93c9.22-4.62 17-12.76 17-27.29m117.29 2.13c0 12-11 14.87-17.71 14.87h-5.67v-28.7h6.72c8.87 0 16.66 3.91 16.66 13.83m-137.12 0c0 12-11 14.87-17.72 14.87h-5.68v-28.7h6.75c8.85 0 16.65 3.91 16.65 13.83"
      />
    </svg>
  ),
  Transilien: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect
        width="265.015"
        height="265.015"
        x="9.223"
        y="9.223"
        fill="#FFF"
        stroke="#25303B"
        stroke-width="18.445"
        rx="54.565"
      />
      <path
        fill="#25303B"
        d="M183.87 204.6a13.27 13.27 0 0 0 1.67-1.29c5.09-4.13 9.85-10.45 13.31-20.25 4.83-13.73 8.24-26 9.18-41.12 1.31-18.6-6.35-47.53-11.43-61l-1.77-4.75c-1.09-3-5.26-9.47-12.65-17.18-6.36-6.62-12.47-7.11-21.77-7.11h-37.76c-9.3 0-15.4.49-21.77 7.11-7.4 7.68-11.57 14.15-12.65 17.18l-1.78 4.78C81.37 94.41 73.72 123.34 75 142c.94 15.06 4.36 27.31 9.21 41.09 3.45 9.82 8.2 16.12 13.3 20.25a16.88 16.88 0 0 0 1.69 1.27l-27.5 30.58a6.87 6.87 0 0 0 0 9 5.31 5.31 0 0 0 8.06 0l9.5-10.54H193.8l9.48 10.54a5.32 5.32 0 0 0 8.07 0 6.89 6.89 0 0 0 0-9Zm-9.23-11.15a9.44 9.44 0 1 1 9.45-9.45 9.46 9.46 0 0 1-9.45 9.45M89.16 132.9c-5.77 0 3.3-50.66 7.43-50.66h88.85c4.49 0 15 50.66 8 50.66Zm9.62 51.1a9.45 9.45 0 1 1 9.45 9.45 9.44 9.44 0 0 1-9.45-9.45m1.92 36.9 10.12-11.25a55.65 55.65 0 0 0 12.8 1.13h35.82a55.57 55.57 0 0 0 12.79-1.13l10.13 11.25Z"
      />
    </svg>
  ),
  3: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="141.73" fill="#9F9825" />
      <path
        fill="#FFF"
        d="M194.35 171.57c0-21.06-12.55-33.83-29.16-37v-.42c16-5.95 24.27-18.3 24.27-33.83 0-20-17.47-38.3-48.09-38.3-18.94 0-32.78 4.89-45.55 13.4l10.86 22.13a41.6 41.6 0 0 1 27.88-10.85c15.75 0 23 8.72 23 18.29 0 11.5-9.13 19.17-23.82 19.17H118V149h15.5c15.32 0 28.5 6 28.5 23.19 0 12.78-10.85 23-28.29 23-12.77 0-23.2-5.32-29.59-9.78l-11.7 23.19c10 5.95 21.92 11.26 43.42 11.26 34.88 0 58.51-21.9 58.51-48.3"
      />
    </svg>
  ),
  9: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="141.73" fill="#D5C900" />
      <path
        fill="#25303B"
        d="M196.72 131.39c0-39.79-14.72-68.31-55.72-68.31-31.49 0-51.49 22.13-51.49 52.14 0 28.09 20 44.47 44.89 44.47 16.18 0 26-5.74 31.07-10.21-2.13 30.21-17.45 47-38.51 47a58.1 58.1 0 0 1-20.65-3.62l-4.67 25.54a99.18 99.18 0 0 0 22.53 2.6c48.08 0 72.55-37.45 72.55-89.58m-30.86-16.6c0 4.47 0 10.42-1.7 13-3 4.68-10.64 8.3-20.22 8.3-14.88 0-24.25-10.22-24.25-24.26 0-13 7.67-25.1 22.56-25.1 15.31 0 23.61 13.83 23.61 28.08"
      />
    </svg>
  ),
  12: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="141.73" fill="#00814F" />
      <path
        fill="#FFF"
        d="M111.92 216.89V66.68H85.74C70.86 78 51.92 88 36.16 94.54l10.23 23c10-4.9 24.47-11.54 33.61-18.75v118.1zm122.69 0v-25.11H172.7c1.91-8.72 17-21.9 24.67-28.94 18.09-16.37 34.48-28.5 34.48-54.25 0-30.64-24.9-46-51.29-46-20.21 0-33.61 6-48.09 18.52L148.22 102c3.62-4 12.77-13.61 26-13.61 16.38 0 24.87 10.84 24.87 23.83 0 14.47-10.83 25.32-20.83 34-20 17.45-37.25 33.4-41.08 50.85v19.79z"
      />
    </svg>
  ),
  13: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="141.73" fill="#98D4E2" />
      <path
        fill="#25303B"
        d="M113.82 216.65V66.43H87.65C72.76 77.7 53.83 87.71 38.07 94.3l10.22 23c10-4.9 24.47-11.49 33.62-18.73v118.1zM236.73 172c0-21.08-12.55-33.84-29.16-37v-.42c16-5.95 24.27-18.29 24.27-33.82 0-20-17.46-38.31-48.09-38.31-18.94 0-32.77 4.89-45.55 13.4l10.87 22.14a41.57 41.57 0 0 1 27.87-10.86c15.74 0 23 8.73 23 18.31 0 11.49-9.13 19.15-23.82 19.15h-15.77v24.81h15.52c15.33 0 28.51 6 28.51 23.2 0 12.77-10.85 23-28.29 23-12.77 0-23.2-5.31-29.57-9.78L134.8 209c10 5.95 21.92 11.26 43.42 11.26 34.89 0 58.51-21.9 58.51-48.29"
      />
    </svg>
  ),
  14: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <circle cx="141.73" cy="141.73" r="141.73" fill="#662483" />
      <path
        fill="#FFF"
        d="M110.19 216.84V66.6H84C69.11 77.89 50.18 87.89 34.42 94.48l10.23 23c10-4.9 24.46-11.5 33.61-18.73v118.09zm131.91-31.28v-23.42h-17.66V66.61h-39.16L127 157.68v27.88h67.66v31.27h29.79v-31.27zm-47.45-23.42h-41.91l30.63-48.07c6-9.37 10.23-18.54 10.86-21.08h.42z"
      />
    </svg>
  ),
  A: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect width="283.46" height="283.46" fill="#E3051C" rx="63.78" />
      <path
        fill="#FFF"
        d="m177.46 216.67-9.36-28.49h-55.5l-9.36 28.49H70.49l53.16-150.35h35.95L213 216.67zM147.26 124a166.42 166.42 0 0 1-6.38-27.43h-.42c-.64 5.53-3.61 17.86-6.81 27.43l-12.76 39.3H160z"
      />
    </svg>
  ),
  E: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect width="283.46" height="283.46" fill="#C04191" rx="63.78" />
      <path fill="#FFF" d="M93.67 216.67V66.54h92.94v27h-59.76v30.84h57.62v25.94h-57.62v39.34h62.94v27z" />
    </svg>
  ),
  L: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect width="283.46" height="283.46" fill="#CEADD2" rx="63.78" />
      <path fill="#25303B" d="M94.74 216.67V66.54h33.17v123.12h60.82v27z" />
    </svg>
  ),
  J: (
    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 283.46 283.46">
      <rect width="283.46" height="283.46" fill="#D5C900" rx="63.78" />
      <path
        fill="#25303B"
        d="M116.75 218.37c-2.55 0-5.11 0-7.66-.21l.86-24.67c1 0 2.33.21 3.4.21 17.64 0 27.85-10.2 27.85-33.17v-94h33.17v96.77c0 27.42-14.66 55.07-57.62 55.07z"
      />
    </svg>
  ),
};
