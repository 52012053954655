import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { transportIcons } from './transports';

function ContactAddress() {
  return (
    <div>
      <h2>Nous rejoindre</h2>
      <address>
        <div className="prepend-icon">
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </span>
          <span>19 rue de Rome 75008 Paris</span>
        </div>

        <div className="prepend-icon">
          <span>
            {transportIcons.Metro} {transportIcons[3]} {transportIcons[12]} {transportIcons[13]} {transportIcons[14]}
          </span>
          <span>Saint-Lazarre</span>
        </div>
        <div className="prepend-icon">
          <span>
            {transportIcons.Metro} {transportIcons[9]}
          </span>
          <span>Saint-Augustin</span>
        </div>
        <div className="prepend-icon">
          <span>
            {transportIcons.RER} {transportIcons.A}
          </span>
          <span>Auber</span>
        </div>
        <div className="prepend-icon">
          <span>
            {transportIcons.RER} {transportIcons.E}
          </span>
          <span>Haussmann - Saint-Lazare</span>
        </div>
        <div className="prepend-icon">
          <span>
            {transportIcons.Transilien} {transportIcons.J} {transportIcons.L}
          </span>
          <span>Saint-Lazarre</span>
        </div>
      </address>
    </div>
  );
}

export default ContactAddress;
